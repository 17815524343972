import gql from "graphql-tag";
import { SITE_FRAGMENT } from "./fragments";

export const GET_SITE = gql`
  query GetSite($id: ID!) {
    site(id: $id) {
      ...site
    }
  }
  ${SITE_FRAGMENT}
`;
