import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { ApolloProvider } from "react-apollo";
import { PixelPadState } from "./components/PixelPad";
import { NavigationState } from "./components/Navigation";
import { SaveDrawingState } from "./components/SaveDrawing";
import { LoginState } from "./components/Login";
import { merge } from "lodash";

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

let httpLinkOptions = {
  uri: "/graphql",
  credentials: "same-origin"
};
if (process.env.NODE_ENV === "development") {
  httpLinkOptions = {
    uri: `http://localhost:8080/graphql`,
    credentials: "include"
  };
}

const httpLink = new HttpLink(httpLinkOptions);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    //TODO do something with graphql error
    console.log("GraphQL Errors:");
    console.log(graphQLErrors);
  }

  if (networkError) {
    //TODO do something with network error
    console.log("Network Error:");
    console.log(networkError);
  }
});

const cache = new InMemoryCache({ addTypename: true });

const link = ApolloLink.from([errorLink, httpLink]);

const client = new ApolloClient({
  cache,
  link: link,
  resolvers: {
    ...merge(NavigationState.resolvers, PixelPadState.resolvers, SaveDrawingState.resolvers, LoginState.resolvers)
  },
  typeDefs: {
    ...merge(NavigationState.typeDefs, PixelPadState.typeDefs, SaveDrawingState.typeDefs, LoginState.typeDefs)
  }
});

cache.writeData({
  data: {
    ...merge(NavigationState.defaults, PixelPadState.defaults, SaveDrawingState.defaults, LoginState.defaults)
  }
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
