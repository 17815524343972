import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core/";
import { styles } from "./styles";

const ErrorSection = ({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h5" className={classes.title} gutterBottom>
      Oh no!
    </Typography>

    <Typography variant="body1" gutterBottom className={classes.body}>
      An unknown error has occurred :(
    </Typography>
    <Link className={classes.link} to="/">Go home</Link>
  </div>
);

export default withStyles(styles)(ErrorSection);
