export const styles = theme => ({
  root: {
    backgroundColor: "#000",
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px 0 ${
      theme.spacing(1)
    }px`,
    position: "fixed",
    width: "100%",
    height: "100%"
  },
  siteHeader: {
    color: "#FFF"
  },
  controls: {
    display: "flex",
    marginTop: theme.spacing(2)
  }
});
