import gql from "graphql-tag";
import {
  CANVAS_INFO_FRAGMENT,
  CANVAS_BASIC_INFO_FRAGMENT,
  DRAG_INFO_FRAGMENT
} from "./fragments";

export const GET_CANVAS = gql`
  query GetCanvas {
    canvas @client {
      ...dragInfo
      ...canvasInfo
    }
    selectedColor @client
  }
  ${DRAG_INFO_FRAGMENT}
  ${CANVAS_INFO_FRAGMENT}
`;

export const GET_CANVAS_BASIC_INFO = gql`
  query GetCanvasBasicInfo {
    canvas @client {
      ...canvasBasicInfo
    }
  }
  ${CANVAS_BASIC_INFO_FRAGMENT}
`;
