import React from "react";
import {
  TableColumnPlusAfter,
  TableColumnRemove,
  TableRowPlusAfter,
  TableRowRemove,
  TableEdit
} from "mdi-material-ui";
import { Fab, Menu } from "@material-ui/core";
import { toRenderProps, withState } from "recompose";
import IconMenuItem from "./IconMenuItem";
import { Mutation } from "react-apollo";
import { TOGGLE_COLUMN, TOGGLE_ROW } from "../CanvasGrid";

const WithState = toRenderProps(withState("anchorEl", "updateAnchorEl", null));

const GridControl = ({
  className,
  canvasWidth,
  canvasHeight,
  canvasWidthMax,
  canvasHeightMax
}) => {
  return (
    <WithState>
      {({ anchorEl, updateAnchorEl }) => {
        const open = Boolean(anchorEl);
        const handleClose = () => {
          updateAnchorEl(null);
        };

        return (
          <React.Fragment>
            <Fab
              className={className}
              size="small"
              aria-owns={open ? "render-props-menu" : undefined}
              aria-haspopup="true"
              onClick={event => {
                updateAnchorEl(event.currentTarget);
              }}
            >
              <TableEdit />
            </Fab>
            <Menu
              id="render-props-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <Mutation mutation={TOGGLE_COLUMN}>
                {toggleColumn =>
                  canvasWidth < canvasWidthMax && (
                    <IconMenuItem
                      onClickHandler={evt => {
                        toggleColumn({
                          variables: {
                            addColumn: true,
                            maxColumns: canvasWidthMax
                          }
                        });
                        handleClose();
                      }}
                      text="Add column"
                    >
                      <TableColumnPlusAfter />
                    </IconMenuItem>
                  )
                }
              </Mutation>
              <Mutation mutation={TOGGLE_COLUMN}>
                {toggleColumn =>
                  canvasWidth > 2 && (
                    <IconMenuItem
                      onClickHandler={evt => {
                        toggleColumn({
                          variables: {
                            addColumn: false,
                            maxColumns: canvasWidthMax
                          }
                        });
                        handleClose();
                      }}
                      text="Remove column"
                    >
                      <TableColumnRemove />
                    </IconMenuItem>
                  )
                }
              </Mutation>
              <Mutation mutation={TOGGLE_ROW}>
                {toggleRow =>
                  canvasHeight < canvasHeightMax && (
                    <IconMenuItem
                      onClickHandler={evt => {
                        toggleRow({
                          variables: {
                            addRow: true,
                            maxRows: canvasHeightMax
                          }
                        });
                        handleClose();
                      }}
                      text="Add row"
                    >
                      <TableRowPlusAfter />
                    </IconMenuItem>
                  )
                }
              </Mutation>
              <Mutation mutation={TOGGLE_ROW}>
                {toggleRow =>
                  canvasHeight > 2 && (
                    <IconMenuItem
                      onClickHandler={evt => {
                        toggleRow({
                          variables: {
                            addRow: false,
                            maxRows: canvasHeightMax
                          }
                        });
                        handleClose();
                      }}
                      text="Remove row"
                    >
                      <TableRowRemove />
                    </IconMenuItem>
                  )
                }
              </Mutation>
            </Menu>
          </React.Fragment>
        );
      }}
    </WithState>
  );
};

export default GridControl;
