import gql from "graphql-tag";

export const CREATE_DRAWING = gql`
  mutation CreateDrawing($input: CreateDrawingInput!) {
    createDrawing(input: $input) {
      id
    }
  }
`;

export const TOGGLE_SAVE_DRAWING_MODAL = gql`
  mutation ToggleSaveDrawingModal($open: Boolean!) {
    toggleSaveDrawingModal(open: $open) @client
  }
`;

export const TOGGLE_DRAWING_SAVED = gql`
  mutation ToggleDrawingSaved($saved: Boolean!) {
    toggleDrawingSaved(saved: $saved) @client
  }
`;
