export const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  whiteText: {
    color: "#FFF"
  },
  link: {
    "&:hover": {
      textDecoration: "none"
    }
  },
  quote: {
    fontStyle: "italic"
  },
  quoteFrom: {},
  citation: {}
});
