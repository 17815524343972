import React from "react";
import classNames from "classnames";
import { Query } from "react-apollo";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { GET_SITE_PALETTE } from "./queries";
import { GET_NAV_OPTIONS } from "../Navigation";
import ErrorMessage from "../Error";
import Palette from "./Palette";
import ColorState from "./Color/state";
import CanvasState from "./CanvasGrid/state";
import CanvasGrid from "./CanvasGrid";
import { GET_CANVAS_BASIC_INFO, GET_CANVAS } from "./CanvasGrid";
import Toolbar from "./Toolbar";
import { merge } from "lodash";

const PixelPad = ({ classes }) => (
  <Query query={GET_NAV_OPTIONS}>
    {({
      data: {
        navOptions: { selectedSiteId }
      }
    }) => {
      return (
        <Query
          query={GET_SITE_PALETTE}
          notifyOnNetworkStatusChange={true}
          variables={{
            siteId: selectedSiteId
          }}
        >
          {({ data, loading, error }) => {
            if (loading) {
              // TODO create a new loading component
              return (
                <div className={classNames(classes.layout, classes.cardGrid)}>
                  LOADING...
                </div>
              );
            }

            if (error) {
              // TODO create a new error component
              return <ErrorMessage error={error} />;
            }

            const {
              name,
              palettes,
              canvasWidthMax,
              canvasHeightMax
            } = data.site;
            return (
              <div className={classes.root}>
                <Typography
                  variant="h5"
                  align="left"
                  className={classes.siteHeader}
                >
                  {name}
                </Typography>
                <div className={classes.controls}>
                  <Palette palette={palettes} />
                  <Query query={GET_CANVAS_BASIC_INFO}>
                    {({
                      data: {
                        canvas: { width, height }
                      }
                    }) => (
                      <Toolbar
                        canvasWidth={width}
                        canvasHeight={height}
                        canvasWidthMax={canvasWidthMax}
                        canvasHeightMax={canvasHeightMax}
                      />
                    )}
                  </Query>
                </div>
                <CanvasGrid sitePalette={palettes} />
              </div>
            );
          }}
        </Query>
      );
    }}
  </Query>
);

const PixelPadState = merge(ColorState, CanvasState);

export default withStyles(styles)(PixelPad);

export { PixelPadState, GET_CANVAS };
