import gql from "graphql-tag";

export const PIXEL_COLOR_FRAGMENT = gql`
  fragment pixelColor on Cell {
    id
    colorId
    hexColor
  }
`;

export const DRAG_INFO_FRAGMENT = gql`
  fragment dragInfo on Canvas {
    dragStarted
  }
`;

export const CANVAS_BASIC_INFO_FRAGMENT = gql`
  fragment canvasBasicInfo on Canvas {
    id
    width
    height
  }
`;

export const CANVAS_INFO_FRAGMENT = gql`
  fragment canvasInfo on Canvas {
    ...canvasBasicInfo
    pixels {
      id
      columns {
        ...pixelColor
      }
    }
  }
  ${CANVAS_BASIC_INFO_FRAGMENT}
  ${PIXEL_COLOR_FRAGMENT}
`;
