import gql from "graphql-tag";

export const LOGIN = gql`
  mutation LoginUser($input: LoginInput!) {
    login(input: $input)
  }
`;

export const LOGOUT = gql`
  mutation LogoutUser {
    logout
  }
`;

export const CHECK_LOGIN = gql`
  mutation CheckLogin {
    checkLogin
  }
`;
