export const styles = theme => ({
  root: {
    width: "100%",
    textDecoration: "none",
    padding: theme.spacing(1),
    color: "#ACACAC",
    "& a": {
      textDecoration: "none",
      color: "inherit"
    },
    "& h1": {
      color: "inherit",
      fontSize: 16
    },
    "& h2": {
      color: "inherit",
      fontSize: 12
    }
    // [theme.breakpoints.down("xs")]: {
    //   "& h1": {
    //     fontSize: 16
    //   },
    //   "& h2": {
    //     fontSize: 12
    //   }
    // }
  }
});
