import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { Query, Mutation } from "react-apollo";
import { GET_CANVAS, GET_CANVAS_BASIC_INFO } from "./queries";
import {
  COLOR_PIXEL,
  TOGGLE_DRAG,
  TOGGLE_COLUMN,
  TOGGLE_ROW
} from "./mutations";
import { TRANSPARENT_COLOR_ID } from "../Color";

const CanvasGrid = ({ classes, sitePalette }) => (
  <Query query={GET_CANVAS}>
    {({
      data: {
        canvas: { width, height, pixels, dragStarted },
        selectedColor
      }
    }) => {
      // TODO: this was a quick and dirty way to get it here instead of using a proper Query!
      const colorCodeMap = sitePalette.reduce(function(map, obj) {
        map[obj.id] = obj.hexColor;
        return map;
      }, {});
      const columnTotal = width;
      const cellSize = 100 / columnTotal;
      const cells = pixels.map(({ columns, id }) =>
        columns.map(props => {
          const { hexColor, colorId } = props;
          const columnId = props.id;
          return (
            <div
              data-rowid={id}
              data-colid={columnId}
              key={columnId}
              className={classes.cell}
              style={{
                width: `${cellSize}%`,
                paddingBottom: `${cellSize}%`,
                background:
                  colorId === TRANSPARENT_COLOR_ID ? "none" : `#${hexColor}`
              }}
            />
          );
        })
      );

      return (
        <Mutation mutation={COLOR_PIXEL}>
          {colorPixel => (
            <Mutation mutation={TOGGLE_DRAG}>
              {toggleDrag => {
                const toggleDragStart = dragStarted =>
                  toggleDrag({ variables: { dragStarted: dragStarted } });

                const colorTheCell = element => {
                  if (element && element.dataset.colid) {
                    colorPixel({
                      variables: {
                        colorId: selectedColor,
                        colId: element.dataset.colid,
                        hexColor: colorCodeMap[selectedColor]
                          ? colorCodeMap[selectedColor]
                          : ""
                      }
                    });
                  }
                };

                const mouseOverHandler = evt => {
                  if (dragStarted) {
                    colorTheCell(evt.target);
                  }
                };

                return (
                  <div
                    className={classes.root}
                    onTouchMove={evt => {
                      evt.preventDefault();
                      const [{ clientX, clientY }] = evt.targetTouches;

                      const element = document.elementFromPoint(
                        clientX - window.pageXOffset,
                        clientY - window.pageYOffset
                      );
                      colorTheCell(element);
                    }}
                    onMouseDown={evt => {
                      colorTheCell(evt.target);
                      toggleDragStart(true);
                    }}
                    onMouseOver={evt => mouseOverHandler(evt)}
                    onMouseUp={evt => toggleDragStart(false)}
                  >
                    {cells}
                  </div>
                );
              }}
            </Mutation>
          )}
        </Mutation>
      );
    }}
  </Query>
);

export default withStyles(styles)(CanvasGrid);

export { TOGGLE_COLUMN, TOGGLE_ROW, GET_CANVAS, GET_CANVAS_BASIC_INFO };
