export const styles = theme => ({
  root: {
    display: "inline-block",
    width: theme.spacing(4),
    height: theme.spacing(6),
    overflow: "hidden"
  },
  button: {
    padding: 0,
    width: "100%",
    height: theme.spacing(4),
    borderRadius: theme.spacing(1) / 2,
    margin: `0 0 ${theme.spacing(1) / 2}px 0`,
    minWidth: 0,
    minHeight: 0,
    "&:hover ~ hr": {
      marginTop: 0
    }
  },
  underline: {
    margin: `${(theme.spacing(3)) / 2}px 0 0 0`,
    height: 2,
    width: "100%",
    background: "#FFF",
    border: "none",
    borderRadius: theme.spacing(1) / 2,
    transition: ".3s ease-in-out"
  },
  selected: {
    margin: 0
  }
});
