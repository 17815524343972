export const styles = theme => ({
  root: {
    marginLeft: "auto",
    "& button": {
      marginLeft: 2
    }
  },
  button: {
    color: "#FFF",
    background: "none",
    border: "2px solid #FFF",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#666"
    },
    "&:last-child": {
      marginRight: 0
    }
  }
});
