export const styles = theme => ({
  root: {
    width: "90%",
    margin: "0 auto",
    lineHeight: 0,
    boxSizing: "border-box",
    display: "table",
    borderTop: "1px solid #333",
    borderLeft: "1px solid #333",
    [theme.breakpoints.up("md")]: {
      maxWidth: 800
    }
  },
  cell: {
    userSelect: "none",
    boxSizing: "inherit",
    border: "1px solid #333",
    borderWidth: "0 1px 1px 0",
    borderRadius: "10%",
    float: "left",
    cursor: "crosshair"
  }
});
