import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";

const IconMenuItem = ({ classes, onClickHandler, text, children }) => {
  return (
    <MenuItem onClick={onClickHandler}>
      <ListItemIcon className={classes.icon}>{children}</ListItemIcon>
      <ListItemText
        classes={{ primary: classes.primary }}
        inset
        primary={text}
      />
    </MenuItem>
  );
};

export default withStyles(styles)(IconMenuItem);
