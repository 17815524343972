import gql from "graphql-tag";
import { SHOW_LOGIN_MODAL_FRAGMENT } from "../Navigation";

export const GET_LOGIN_MODAL_OPTIONS = gql`
  query GetLoginModalOptions {
    navOptions @client {
      ...loginModal
    }
  }
  ${SHOW_LOGIN_MODAL_FRAGMENT}
`;

export const GET_LOGGED_IN_USER_ID = gql`
  query GetLoggedInUserId {
    userId @client
  }
`;
