import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import Color from "../Color";
import { TRANSPARENT_COLOR_ID } from "../Color";

const Palette = ({ classes, palette }) => {
  const colors = palette.map(({ id, hexColor }) => (
    <Color key={id} colorId={id} hexColor={hexColor} />
  ));
  colors.push(
    <Color
      key={TRANSPARENT_COLOR_ID}
      colorId={TRANSPARENT_COLOR_ID}
      style={{ marginLeft: "20px" }}
      hexColor={""}
    />
  );
  return <ul className={classes.root}>{colors}</ul>;
};

export default withStyles(styles)(Palette);
