import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography
} from "@material-ui/core";
import { Query, Mutation } from "react-apollo";
import { GET_REGISTER_MODAL_OPTIONS } from "./queries";
import { TOGGLE_REGISTER_MODAL } from "../Navigation";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { compose, withState, toRenderProps } from "recompose";
import { REGISTER } from "./mutations";

const WithState = toRenderProps(
  compose(
    withState("firstName", "updateFirstName", ""),
    withState("firstNameErrorText", "updateFirstNameErrorText", ""),
    withState("lastName", "updateLastName", ""),
    withState("lastNameErrorText", "updateLastNameErrorText", ""),
    withState("email", "updateEmail", ""),
    withState("emailErrorText", "updateEmailErrorText", ""),
    withState("username", "updateUsername", ""),
    withState("usernameErrorText", "updateUsernameErrorText", ""),
    withState("password", "updatePassword", ""),
    withState("passwordErrorText", "updatePasswordErrorText", ""),
    withState("errorContent", "updateErrorContent", ""),
    withState("confirmPassword", "updateConfirmPassword", ""),
    withState("confirmPasswordErrorText", "updateConfirmPasswordErrorText", "")
  )
);

const requiredValidation = (field, name) => {
  if (field && field.trim() !== "") {
    return "";
  } else {
    return name + " is required";
  }
};

const tooShortValidation = (field, name) => {
  if (field && field.trim().length >= 8) {
    return "";
  } else {
    return name + " is too short";
  }
};

const matchValidation = (fieldOne, fieldTwo, name) => {
  if (fieldOne === fieldTwo) {
    return "";
  } else {
    return name + " must match";
  }
};

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailValidation = (email, name) => {
  if (email && emailRegex.test(email)) {
    return "";
  } else {
    return name + " must be a valid email address";
  }
};

const Register = ({ classes }) => (
  <WithState>
    {({
      firstName,
      updateFirstName,
      firstNameErrorText,
      updateFirstNameErrorText,
      lastName,
      updateLastName,
      lastNameErrorText,
      updateLastNameErrorText,
      email,
      updateEmail,
      emailErrorText,
      updateEmailErrorText,
      username,
      updateUsername,
      usernameErrorText,
      updateUsernameErrorText,
      password,
      updatePassword,
      passwordErrorText,
      updatePasswordErrorText,
      confirmPassword,
      updateConfirmPassword,
      confirmPasswordErrorText,
      updateConfirmPasswordErrorText,
      errorContent,
      updateErrorContent
    }) => {
      return (
        <Query query={GET_REGISTER_MODAL_OPTIONS}>
          {({
            data: {
              navOptions: { showRegisterModal }
            }
          }) => (
            <Mutation mutation={TOGGLE_REGISTER_MODAL}>
              {toggleRegisterModal => {
                const closeRegister = () => {
                  updateFirstName("");
                  updateFirstNameErrorText("");
                  updateLastName("");
                  updateLastNameErrorText("");
                  updateEmail("");
                  updateEmailErrorText("");
                  updateUsername("");
                  updateUsernameErrorText("");
                  updatePassword("");
                  updatePasswordErrorText("");
                  updateConfirmPassword("");
                  updateConfirmPasswordErrorText("");

                  toggleRegisterModal({ variables: { open: false } });
                };
                return (
                  <Mutation
                    mutation={REGISTER}
                    onCompleted={evt => {
                      closeRegister();
                      //TODO open LOGIN
                    }}
                    onError={error => {
                      let errorString = error.graphQLErrors.map(
                        ({ message }, i) => message + " "
                      );
                      updateErrorContent(errorString);
                    }}
                  >
                    {(createUser, { loading, error }) => (
                      <Dialog
                        open={showRegisterModal}
                        onClose={closeRegister}
                        PaperProps={{ className: classes.paper }}
                        aria-labelledby="signup-dialog-title"
                      >
                        <form
                          onSubmit={evt => {
                            evt.preventDefault();
                            // send save message post validation
                            const firstNameValidation = requiredValidation(
                                firstName,
                                "First name"
                              ),
                              lastNameValidation = requiredValidation(
                                lastName,
                                "Last name"
                              ),
                              emailAddressValidation = emailValidation(
                                email,
                                "Email"
                              ),
                              usernameValidation = requiredValidation(
                                username,
                                "Username"
                              ),
                              passwordValidation = tooShortValidation(
                                password,
                                "Password"
                              ),
                              confirmPasswordValidation = matchValidation(
                                password,
                                confirmPassword,
                                "Confirm password"
                              );

                            updateFirstNameErrorText(firstNameValidation);
                            updateLastNameErrorText(lastNameValidation);
                            updateEmailErrorText(emailAddressValidation);
                            updateUsernameErrorText(usernameValidation);
                            updatePasswordErrorText(passwordValidation);
                            updateConfirmPasswordErrorText(
                              confirmPasswordValidation
                            );

                            if (
                              firstNameValidation === "" &&
                              lastNameValidation === "" &&
                              emailAddressValidation === "" &&
                              usernameValidation === "" &&
                              passwordValidation === "" &&
                              confirmPasswordValidation === ""
                            ) {
                              // create user
                              createUser({
                                variables: {
                                  input: {
                                    firstName,
                                    lastName,
                                    email,
                                    username,
                                    password
                                  }
                                }
                              });
                            }
                          }}
                        >
                          <DialogTitle id="signup-dialog-title">
                            Sign up
                          </DialogTitle>
                          <DialogContent>
                            {errorContent !== "" && (
                              <Typography
                                variant="overline"
                                gutterBottom
                                style={{ color: "#f50057" }}
                                align="center"
                              >
                                {errorContent}
                              </Typography>
                            )}
                            <TextField
                              autoFocus
                              margin="dense"
                              id="firstname"
                              label="First Name"
                              type="text"
                              fullWidth
                              error={firstNameErrorText !== ""}
                              helperText={firstNameErrorText}
                              value={firstName}
                              onChange={evt =>
                                updateFirstName(evt.target.value)
                              }
                              onBlur={evt =>
                                updateFirstNameErrorText(
                                  requiredValidation(firstName, "First name")
                                )
                              }
                            />
                            <TextField
                              margin="dense"
                              id="lastname"
                              label="Last Name"
                              type="text"
                              fullWidth
                              error={lastNameErrorText !== ""}
                              helperText={lastNameErrorText}
                              value={lastName}
                              onChange={evt => updateLastName(evt.target.value)}
                              onBlur={evt =>
                                updateLastNameErrorText(
                                  requiredValidation(lastName, "Last name")
                                )
                              }
                            />
                            <TextField
                              margin="dense"
                              id="email"
                              label="Email"
                              type="email"
                              fullWidth
                              error={emailErrorText !== ""}
                              helperText={emailErrorText}
                              value={email}
                              onChange={evt => updateEmail(evt.target.value)}
                              onBlur={evt =>
                                updateEmailErrorText(
                                  emailValidation(email, "Email")
                                )
                              }
                            />
                            <TextField
                              margin="dense"
                              id="username_register"
                              label="Username"
                              type="text"
                              fullWidth
                              error={usernameErrorText !== ""}
                              helperText={usernameErrorText}
                              value={username}
                              onChange={evt => updateUsername(evt.target.value)}
                              onBlur={evt =>
                                updateUsernameErrorText(
                                  requiredValidation(username, "Username")
                                )
                              }
                            />
                            <TextField
                              margin="dense"
                              id="password_register"
                              label="Password"
                              type="password"
                              fullWidth
                              error={passwordErrorText !== ""}
                              helperText={passwordErrorText}
                              value={password}
                              onChange={evt => updatePassword(evt.target.value)}
                              onBlur={evt =>
                                updatePasswordErrorText(
                                  tooShortValidation(password, "Password")
                                )
                              }
                            />
                            <TextField
                              margin="dense"
                              id="confirmpassword"
                              label="Confirm Password"
                              type="password"
                              fullWidth
                              error={confirmPasswordErrorText !== ""}
                              helperText={confirmPasswordErrorText}
                              value={confirmPassword}
                              onChange={evt =>
                                updateConfirmPassword(evt.target.value)
                              }
                              onBlur={evt =>
                                updateConfirmPasswordErrorText(
                                  matchValidation(
                                    password,
                                    confirmPassword,
                                    "Confirm password"
                                  )
                                )
                              }
                            />
                          </DialogContent>
                          <DialogActions className={classes.loginWrapper}>
                            <Button type="submit" className={classes.button}>
                              Submit
                            </Button>
                          </DialogActions>
                        </form>
                      </Dialog>
                    )}
                  </Mutation>
                );
              }}
            </Mutation>
          )}
        </Query>
      );
    }}
  </WithState>
);

export default withStyles(styles)(Register);
