import React from "react";
import GridControl from "../GridControl";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { Fab } from "@material-ui/core";
import { ContentSaveOutline } from "mdi-material-ui";
import { Mutation, Query } from "react-apollo";
import { TOGGLE_SAVE_DRAWING_MODAL } from "../../SaveDrawing";
import { GET_LOGGED_IN_USER_ID } from "../../Login";
import { TOGGLE_LOGIN_MODAL } from "../../Navigation";

const Toolbar = ({
  classes,
  canvasWidth,
  canvasHeight,
  canvasWidthMax,
  canvasHeightMax
}) => {
  return (
    <div className={classes.root}>
      <Query query={GET_LOGGED_IN_USER_ID}>
        {({ data }) => (
          <Mutation mutation={TOGGLE_SAVE_DRAWING_MODAL}>
            {(toggleSaveDrawingModal, { loading, error }) => (
              <Mutation mutation={TOGGLE_LOGIN_MODAL}>
                {toggleLoginModal => (
                  <Fab
                    className={classes.button}
                    size="small"
                    onClick={evt => {
                      if (data.userId !== "") {
                        // we initalize the save dialog box only if the
                        // userId is populated (post-login)
                        toggleSaveDrawingModal({
                          variables: { open: true }
                        });
                      } else {
                        // show the login page
                        toggleLoginModal({
                          variables: { open: true }
                        });
                      }
                    }}
                  >
                    <ContentSaveOutline />
                  </Fab>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Query>
      <GridControl
        className={classes.button}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        canvasWidthMax={canvasWidthMax}
        canvasHeightMax={canvasHeightMax}
      />
    </div>
  );
};

export default withStyles(styles)(Toolbar);
