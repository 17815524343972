import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";

const SiteName = ({ classes, name, subHeader, linkTo }) => {
  return (
    <div className={classes.root}>
      <Link to={linkTo}>
        <Typography variant="h1" gutterBottom>
          {name}
        </Typography>
        <Typography variant="h2" gutterBottom>
          {subHeader}
        </Typography>
      </Link>
    </div>
  );
};

export default withStyles(styles)(SiteName);
