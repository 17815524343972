import React, { Fragment } from "react";
import Site from "../Site";
import TheStoryGoesOn from "../TheStoryGoesOn";

//TODO in the future, we will have a way to select sites and id will be passed on to the Site props
const Dashboard = () => {
  return (
    <Fragment>
      <TheStoryGoesOn />
      <Site id={"U2l0ZTox"} />
    </Fragment>
  );
};

export default Dashboard;
