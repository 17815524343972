import gql from "graphql-tag";

export const SELECTED_SITE_ID_FRAGMENT = gql`
  fragment selectedSite on NavOptions {
    selectedSiteId
  }
`;

export const SHOW_LOGIN_MODAL_FRAGMENT = gql`
  fragment loginModal on NavOptions {
    showLoginModal
  }
`;

export const SHOW_REGISTER_MODAL_FRAGMENT = gql`
  fragment registerModal on NavOptions {
    showRegisterModal
  }
`;

export const LOGGED_IN_FRAGMENT = gql`
  fragment loggedInFragment on NavOptions {
    loggedIn
  }
`;
