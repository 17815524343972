import gql from "graphql-tag";
import {
  SELECTED_SITE_ID_FRAGMENT,
  SHOW_LOGIN_MODAL_FRAGMENT,
  SHOW_REGISTER_MODAL_FRAGMENT,
  LOGGED_IN_FRAGMENT
} from "./fragments";

export const GET_NAV_OPTIONS = gql`
  query GetNavOptions {
    navOptions @client {
      ...selectedSite
      ...loginModal
      ...registerModal
      ...loggedInFragment
    }
  }
  ${SELECTED_SITE_ID_FRAGMENT}
  ${SHOW_LOGIN_MODAL_FRAGMENT}
  ${SHOW_REGISTER_MODAL_FRAGMENT}
  ${LOGGED_IN_FRAGMENT}
`;
