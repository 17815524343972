import gql from "graphql-tag";

export const COLOR_PIXEL = gql`
  mutation ColorPixel(
    $hexColor: String!
    $colorId: String!
    $rowId: Int!
    $colId: Int!
  ) {
    colorPixel(
      hexColor: $hexColor
      colorId: $colorId
      rowId: $rowId
      colId: $colId
    ) @client
  }
`;

export const TOGGLE_DRAG = gql`
  mutation ToggleDrag($dragStarted: Boolean!) {
    toggleDrag(dragStarted: $dragStarted) @client {
      dragStarted
    }
  }
`;

export const TOGGLE_COLUMN = gql`
  mutation ToggleColumn($addColumn: Boolean!) {
    toggleColumn(addColumn: $addColumn, maxColumns: $maxColumns) @client
  }
`;

export const TOGGLE_ROW = gql`
  mutation ToggleRow($addRow: Boolean!) {
    toggleRow(addRow: $addRow, maxRows: $maxRows) @client
  }
`;
