export const styles = theme => ({
  container: {
    backgroundColor: "#ACACAC",
    position: "relative",
    borderRadius: theme.spacing(3),
    minHeight: "250px",
    minWidth: "250px", //temporary fix
    textAlign: "center"
  },
  bg: {
    width: "75%",
    margin: "10%"
  },
  title: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: "absolute",
    color: "#FFF"
  },
  export: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: "absolute",
    color: "#FFF"
  }
});
