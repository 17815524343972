export const styles = theme => ({
  root: {
    flexGrow: 1,
    position: "relative"
  },
  grow: {
    flexGrow: 1
  },
  toolBar: {
    backgroundColor: "#2e2920",
    minHeight: `${theme.spacing(8)}px`
  },
  // menuButton: {
  //   marginLeft: -12,
  //   marginRight: 20
  // },
  icon: {
    width: theme.spacing(12),
    marginRight: theme.spacing(1)
  },
  actionButton: {
    color: "inherit",
    minWidth: 86,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.2)"
    },
    padding: `${theme.spacing(1)}px 14px`,
    [theme.breakpoints.down("sm")]: {
      minWidth: 64,
      padding: 2
    }
  },
  button: {
    position: "absolute",
    top: theme.spacing(7.5),
    right: theme.spacing(4)
  },
  drawIcon: {
    marginRight: theme.spacing(1)
  }
});
