import gql from "graphql-tag";

export const GET_SAVE_DRAWING_MODAL_OPTIONS = gql`
  query GetSaveDrawingModalView {
    saveDrawingModal @client
    drawingSaved @client
  }
`;

export const GET_LAST_SAVED_DRAWING_ID = gql`
  query GetLastSavedDrawingId {
    lastSavedDrawingId @client
  }
`;
