import React, { Component } from "react";
import Dashboard from "../Dashboard";
import PixelPad from "../PixelPad";
import Navigation from "../Navigation";
import ErrorSection from "../ErrorSection";
import Login from "../Login";
import Register from "../Register";
import SaveDrawing from "../SaveDrawing";
import { CssBaseline } from "@material-ui/core";
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as routes from "../../constants/routes";
import { styles } from "./styles";

class App extends Component {
  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        background: {
          paper: "#333",
          default: "#333"
        }
      }
    });

    return (
      <Router>
        <React.Fragment>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navigation />
            <main className={classes.container}>
              <Route
                exact
                path={routes.DASHBOARD}
                component={() => <Dashboard />}
              />
              <Route
                exact
                path={routes.PIXELPAD}
                component={() => <PixelPad />}
              />
              <Route
                exact
                path={routes.ERROR}
                component={() => <ErrorSection />}
              />
            </main>
            <Login />
            <Register />
            <SaveDrawing />
            {/* <FooterNavigation /> */}
          </ThemeProvider>
        </React.Fragment>
      </Router>
    );
  }
}

export default withStyles(styles)(App);
