export const styles = theme => ({
  root: {
    backgroundColor: "#333",
    color: "#FFF",
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: `${theme.spacing(6)}px`
    }
  },
  image: {
    margin: theme.spacing(1),
    width: "100%"
  },
  title: {
    color: "#FFF"
  },
  body: {
    color: "#FFF",
    marginBottom: theme.spacing(3)
  },
  link: {
    color: "#FFF",
    "&:hover": {
      textDecoration: "none"
    }
  }
});
