import React from "react";
import { withWidth } from "@material-ui/core";
import { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";

const Image = ({ alt, sm, md, lg, width, ...otherProps }) => {
  if (sm && isWidthDown("sm", width)) {
    return <img alt={alt} src={sm} {...otherProps} />;
  }

  if (md && isWidthDown("md", width)) {
    return <img alt={alt} src={md} {...otherProps} />;
  }

  if (lg && isWidthUp("md", width)) {
    return <img alt={alt} src={lg} {...otherProps} />;
  }
  return <React.Fragment />;
};

export default withWidth()(Image);
