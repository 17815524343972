import {
  SELECTED_SITE_ID_FRAGMENT,
  SHOW_LOGIN_MODAL_FRAGMENT,
  SHOW_REGISTER_MODAL_FRAGMENT,
  LOGGED_IN_FRAGMENT
} from "./fragments";

export const NAV_ID = "navigation";

const defaults = {
  navOptions: {
    id: NAV_ID,
    selectedSiteId: "U2l0ZTox",
    showLoginModal: false,
    showRegisterModal: false,
    loggedIn: false,
    __typename: "NavOptions"
  }
};

const resolvers = {
  Mutation: {
    updateSelectedSite: (_, { selectedSiteId }, { cache }) => {
      cache.writeFragment({
        id: `NavOptions:${NAV_ID}`,
        fragment: SELECTED_SITE_ID_FRAGMENT,
        data: {
          selectedSiteId: selectedSiteId,
          __typename: "NavOptions"
        }
      });
      return null;
    },
    toggleLoginModal: (_, { open }, { cache }) => {
      cache.writeFragment({
        id: `NavOptions:${NAV_ID}`,
        fragment: SHOW_LOGIN_MODAL_FRAGMENT,
        data: {
          showLoginModal: open,
          __typename: "NavOptions"
        }
      });
      return null;
    },
    toggleRegisterModal: (_, { open }, { cache }) => {
      cache.writeFragment({
        id: `NavOptions:${NAV_ID}`,
        fragment: SHOW_REGISTER_MODAL_FRAGMENT,
        data: {
          showRegisterModal: open,
          __typename: "NavOptions"
        }
      });
      return null;
    },
    toggleLoggedIn: (_, { loggedIn }, { cache }) => {
      cache.writeFragment({
        id: `NavOptions:${NAV_ID}`,
        fragment: LOGGED_IN_FRAGMENT,
        data: {
          loggedIn: loggedIn,
          __typename: "NavOptions"
        }
      });
      return null;
    }
  }
};

const typeDefs = `
type NavOptions {
  id: ID!
  selectedSiteId: ID!
  showLoginModal: Boolean!
  showRegisterModal: Boolean!
  loggedIn: Boolean!
}
`;

export default {
  defaults: defaults,
  resolvers: resolvers,
  typeDefs: typeDefs
};
