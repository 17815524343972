import {
  PIXEL_COLOR_FRAGMENT,
  CANVAS_INFO_FRAGMENT,
  DRAG_INFO_FRAGMENT
} from "./fragments";

import { TRANSPARENT_COLOR_ID } from "../Color";

const DEFAULT_SIZE = 12;
const CANVAS_ID = "current";

let cellId = 0;
let rowId = 0;

const getEmptyCell = id => ({
  __typename: "Cell",
  id: id,
  hexColor: "",
  colorId: TRANSPARENT_COLOR_ID
});

const getRow = (id, columns) => ({
  __typename: "Row",
  id: id,
  columns: columns
});

const generateEmptyCells = total => {
  let cols = new Array(total);
  for (let i = 0; i < total; i++) {
    cols[i] = getEmptyCell(cellId);
    cellId++;
  }

  return cols;
};

const defaultPixels = (rowSize, columnSize) => {
  let rows = new Array(rowSize);

  const totalSize = rowSize * columnSize;

  const cells = generateEmptyCells(totalSize);

  for (let i = 0; i < rows.length; i++) {
    rows[i] = getRow(
      rowId,
      cells.slice(i * columnSize, i * columnSize + columnSize)
    );
    rowId++;
  }
  return rows;
};

const defaults = {
  canvas: {
    id: CANVAS_ID,
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
    pixels: defaultPixels(DEFAULT_SIZE, DEFAULT_SIZE),
    dragStarted: false,
    __typename: "Canvas"
  }
};

const resolvers = {
  Mutation: {
    colorPixel: (_, { colorId, hexColor, colId }, { cache }) => {
      const id = `Cell:${colId}`;
      cache.writeFragment({
        id,
        fragment: PIXEL_COLOR_FRAGMENT,
        data: {
          id: colId,
          colorId: colorId,
          hexColor: hexColor,
          __typename: "Cell"
        }
      });
      return null;
    },
    toggleDrag: (_, { dragStarted }, { cache }) => {
      const id = `Canvas:${CANVAS_ID}`;
      cache.writeFragment({
        id: id,
        fragment: DRAG_INFO_FRAGMENT,
        data: {
          id: CANVAS_ID,
          dragStarted: dragStarted,
          __typename: "Canvas"
        }
      });
      return { dragStarted: dragStarted, __typename: "Canvas" };
    },
    toggleColumn: (_, { addColumn, maxColumns }, { cache }) => {
      // read current data
      const id = `Canvas:${CANVAS_ID}`;
      const currentPixels = cache.readFragment({
        id: id,
        fragment: CANVAS_INFO_FRAGMENT,
        fragmentName: "canvasInfo"
      });

      // get the current total rows and cols
      let pixels = currentPixels.pixels.slice(0);
      const currentTotalRows = pixels.length;
      const currentTotalCols = pixels[0].columns.length;

      const newTotalCols = addColumn
        ? currentTotalCols + 2
        : currentTotalCols - 2;

      if (newTotalCols < 1 || newTotalCols > maxColumns) {
        return null;
      }

      if (addColumn) {
        // add new cells to each row
        for (let i = 0; i < currentTotalRows; i++) {
          pixels[i].columns.splice(0, 0, getEmptyCell(cellId));
          cellId++;
          pixels[i].columns.splice(
            currentTotalCols + 1,
            0,
            getEmptyCell(cellId)
          );
          cellId++;
        }
      } else {
        // remove cells from each row
        for (let i = 0; i < currentTotalRows; i++) {
          pixels[i].columns.splice(currentTotalCols - 1, 1);
          pixels[i].columns.splice(0, 1);
        }
      }

      cache.writeFragment({
        id: id,
        fragment: CANVAS_INFO_FRAGMENT,
        fragmentName: "canvasInfo",
        data: {
          id: CANVAS_ID,
          width: newTotalCols,
          height: currentTotalRows,
          pixels: pixels,
          __typename: "Canvas"
        }
      });

      return null;
    },
    toggleRow: (_, { addRow, maxRows }, { cache }) => {
      // read current data
      const id = `Canvas:${CANVAS_ID}`;
      const currentPixels = cache.readFragment({
        id: id,
        fragment: CANVAS_INFO_FRAGMENT,
        fragmentName: "canvasInfo"
      });

      // get the current total rows and cols
      let pixels = currentPixels.pixels.slice(0);
      const currentTotalRows = pixels.length;
      const currentTotalCols = pixels[0].columns.length;

      const newTotalRows = addRow ? currentTotalRows + 2 : currentTotalRows - 2;

      if (newTotalRows < 1 || newTotalRows > maxRows) {
        return null;
      }

      if (addRow) {
        // create cells
        let cells = generateEmptyCells(currentTotalCols * 2);

        // add one set at the beginning of the rows array
        pixels.splice(0, 0, getRow(rowId, cells.slice(0, currentTotalCols)));
        rowId++;

        // add one set at the end of the rows array
        pixels.splice(
          pixels.length,
          0,
          getRow(rowId, cells.slice(currentTotalCols))
        );
        rowId++;
      } else {
        // remove cells from the end of the rows array
        pixels.splice(currentTotalRows - 1, 1);

        // remove cells from the beginning of the rows array
        pixels.splice(0, 1);
      }

      cache.writeFragment({
        id: id,
        fragment: CANVAS_INFO_FRAGMENT,
        fragmentName: "canvasInfo",
        data: {
          id: CANVAS_ID,
          width: currentTotalCols,
          height: newTotalRows,
          pixels: pixels,
          __typename: "Canvas"
        }
      });

      return null;
    }
  }
};
const typeDefs = `
type Cell {
  id: Int!
  hexColor: String!
  colorId: String!
}

type Canvas {
  id: ID!
  dragStarted: Boolean!
  width: Int!
  height: Int!
  pixels: [Row!]!
}

type Row {
  id: Int!
  columns: [Cell!]!
}
`;
export default {
  defaults: defaults,
  resolvers: resolvers,
  typeDefs: typeDefs
};
