import gql from "graphql-tag";

const DRAWING_FRAGMENT = gql`
  fragment drawing on Drawing {
    id
    title
    description
    width
    height
    pixelData {
      id
      hexColor
    }
  }
`;

export default DRAWING_FRAGMENT;
