import React from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography
} from "@material-ui/core";
import { Query, Mutation } from "react-apollo";
import {
  GET_LOGIN_MODAL_OPTIONS,
  GET_LOGGED_IN_USER_ID
} from "./queries";
import {
  TOGGLE_LOGIN_MODAL,
  TOGGLE_REGISTER_MODAL,
  TOGGLE_LOGGED_IN
} from "../Navigation";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { CHECK_LOGIN, LOGIN, LOGOUT } from "./mutations";
import { toRenderProps, withState, compose } from "recompose";
import LoginState from "./state";

const WithState = toRenderProps(
  compose(
    withState("username", "updateUsername", ""),
    withState("usernameErrorText", "updateUsernameErrorText", ""),
    withState("password", "updatePassword", ""),
    withState("passwordErrorText", "updatePasswordErrorText", ""),
    withState("errorContent", "updateErrorContent", "")
  )
);

//TODO all of these validations need to be another component or a utility class
const validateUsername = user => {
  if (user && user.trim() !== "") {
    return "";
  } else {
    return "Username is required";
  }
};

const validatePassword = pass => {
  if (pass && pass.trim().length >= 8) {
    return "";
  } else {
    return "Password is too short";
  }
};

const Login = ({ classes }) => (
  <WithState>
    {({
      username,
      updateUsername,
      usernameErrorText,
      updateUsernameErrorText,
      password,
      updatePassword,
      passwordErrorText,
      updatePasswordErrorText,
      errorContent,
      updateErrorContent
    }) => {
      return (
        <Query query={GET_LOGIN_MODAL_OPTIONS}>
          {({
            data: {
              navOptions: { showLoginModal }
            }
          }) => (
            <Mutation mutation={TOGGLE_LOGIN_MODAL}>
              {toggleLoginModal => {
                const closeLogin = () => {
                  toggleLoginModal({ variables: { open: false } });
                  updateUsername("");
                  updatePassword("");
                  updateUsernameErrorText("");
                  updatePasswordErrorText("");
                  updateErrorContent("");
                };
                return (
                  <Mutation mutation={TOGGLE_LOGGED_IN}>
                    {toggleLoggedIn => (
                      <Mutation
                        mutation={LOGIN}
                        onCompleted={evt => {
                          toggleLoggedIn({ variables: { loggedIn: true } });
                          closeLogin();
                        }}
                        onError={error => {
                          let errorString = error.graphQLErrors.map(
                            ({ message }, i) => message + " "
                          );
                          updateErrorContent(errorString);
                        }}
                        update={(cache, { data: { login } }) => {
                          cache.writeQuery({
                            query: GET_LOGGED_IN_USER_ID,
                            data: { userId: login }
                          });
                        }}
                      >
                        {(login, { loading, error }) => (
                          <Dialog
                            open={showLoginModal}
                            onClose={closeLogin}
                            PaperProps={{ className: classes.paper }}
                          >
                            <form
                              onSubmit={evt => {
                                evt.preventDefault();

                                const usernameValidation = validateUsername(
                                    username
                                  ),
                                  passwordValidation = validatePassword(
                                    password
                                  );

                                updateUsernameErrorText(usernameValidation);
                                updatePasswordErrorText(passwordValidation);

                                if (
                                  usernameValidation === "" &&
                                  passwordValidation === ""
                                ) {
                                  login({
                                    variables: {
                                      input: {
                                        username: username,
                                        password: password
                                      }
                                    }
                                  });
                                }
                              }}
                            >
                              <DialogContent>
                                {errorContent !== "" && (
                                  <Typography
                                    variant="overline"
                                    gutterBottom
                                    style={{ color: "#f50057" }}
                                    align="center"
                                  >
                                    {errorContent}
                                  </Typography>
                                )}
                                <TextField
                                  autoFocus
                                  margin="dense"
                                  id="username"
                                  label="Username"
                                  type="text"
                                  fullWidth
                                  value={username}
                                  error={usernameErrorText !== ""}
                                  helperText={usernameErrorText}
                                  onChange={evt =>
                                    updateUsername(evt.target.value)
                                  }
                                  onBlur={evt => {
                                    updateUsernameErrorText(
                                      validateUsername(username)
                                    );
                                  }}
                                />
                                <TextField
                                  margin="dense"
                                  id="password"
                                  label="Password"
                                  type="password"
                                  fullWidth
                                  value={password}
                                  error={passwordErrorText !== ""}
                                  helperText={passwordErrorText}
                                  onChange={evt =>
                                    updatePassword(evt.target.value)
                                  }
                                  onBlur={evt => {
                                    updatePasswordErrorText(
                                      validatePassword(password)
                                    );
                                  }}
                                />
                              </DialogContent>
                              <DialogActions className={classes.loginWrapper}>
                                <Button
                                  type="submit"
                                  className={classes.button}
                                >
                                  Login
                                </Button>
                                <Mutation
                                  mutation={TOGGLE_REGISTER_MODAL}
                                  onCompleted={closeLogin}
                                >
                                  {toggleRegisterModal => (
                                    <Button
                                      onClick={evt => {
                                        toggleRegisterModal({
                                          variables: { open: true }
                                        });
                                        //closeLogin();
                                      }}
                                      className={classes.button}
                                    >
                                      Sign up
                                    </Button>
                                  )}
                                </Mutation>
                              </DialogActions>
                            </form>
                          </Dialog>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                );
              }}
            </Mutation>
          )}
        </Query>
      );
    }}
  </WithState>
);

export default withStyles(styles)(Login);

export { CHECK_LOGIN, GET_LOGGED_IN_USER_ID, LOGOUT, LoginState };
