const defaults = {
  saveDrawingModal: false,
  drawingSaved: false,
  lastSavedDrawingId: null
};

const resolvers = {
  Mutation: {
    toggleSaveDrawingModal: (_, { open }, { cache }) => {
      cache.writeData({ data: { saveDrawingModal: open } });
      return null;
    },
    toggleDrawingSaved: (_, { saved }, { cache }) => {
      cache.writeData({ data: { drawingSaved: saved } });
      return null;
    }
  }
};

export default {
  defaults: defaults,
  resolvers: resolvers
};
