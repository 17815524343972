import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core/";
import { styles } from "./styles";
import Quote from "./Quote";
import storyGoesOnLargeImage from "./images/the_story_goes_on_lg.png";
import storyGoesOnMediumImage from "./images/the_story_goes_on_md.png";
import storyGoesOnSmallImage from "./images/the_story_goes_on_sm.png";
import etchingSmallImage from "./images/etching_layout_graphic_sm.png";
import etchingLargeImage from "./images/etching_layout_graphic_lg.png";
import ResponsiveImage from "../ResponsiveImage";

const TheStoryGoesOn = ({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h5" className={classes.title} gutterBottom>
      The Story Goes On
    </Typography>
    <ResponsiveImage
      className={classes.image}
      alt="story goes on wall"
      sm={storyGoesOnSmallImage}
      md={storyGoesOnMediumImage}
      lg={storyGoesOnLargeImage}
    />
    <Typography variant="body1" gutterBottom className={classes.body}>
      In celebration of the region’s incredible artistic legacy, The Story Goes
      On is an interactive public art piece that provides space for emerging
      creative visions. This project pays particular tribute to local
      visionaries who have used their craft to advance social change. These
      thought leaders provided a critique on the world as it was and dared to
      imagine the world as it could be. We invite YOU to continue that
      tradition. Move the pixels to craft an image, tell a story, begin a
      conversation. Dream.
    </Typography>
    <Typography variant="body1" gutterBottom className={classes.body}>
      This project could not have been completed without the support of the{" "}
      <a
        className={classes.link}
        href={"https://durhamnc.gov/453/Public-Art-Committee"}
        target="_blank"
        rel="noopener noreferrer"
      >
        City of Durham Public Art Program
      </a>
      ,{" "}
      <a
        className={classes.link}
        href={"https://durhamcentralpark.org/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Durham Central Park, Inc.
      </a>
      , and{" "}
      <a
        className={classes.link}
        href={"https://livelibertywarehouse.com/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        the Liberty Warehouse Apartments
      </a>
      .
    </Typography>
    <Typography variant="body1" gutterBottom className={classes.body}>
      We would also like to sincerely thank{" "}
      <a
        className={classes.link}
        href={"http://www.bulldurhamfabrications.com/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Bull Durham Fabrications
      </a>
      ,{" "}
      <a
        className={classes.link}
        href={"http://www.lysaghtassociates.com/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Lysaght Associates
      </a>
      ,{" "}
      <a
        className={classes.link}
        href={"https://www.brokermfg.com/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Broker Manufacturing
      </a>
      , and{" "}
      <a
        className={classes.link}
        href={"https://www.gipo-rpi.com/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Reprocessed Plastics
      </a>{" "}
      for their role in making this project possible.
    </Typography>
    <Typography variant="body1" gutterBottom className={classes.body}>
      Hidden throughout the piece are several quotations from figures who have
      had and continue to have an influence within the City of Durham. We would
      like to thank those individuals and/or the organizations that represent
      their estates for the use of their words.
    </Typography>
    <ResponsiveImage
      className={classes.image}
      alt="quotation on wall"
      sm={etchingSmallImage}
      lg={etchingLargeImage}
    />
    <ol>
      <Quote
        quote="This to me is what art is all about—showing the spirit of man
          struggling above the mundane, above the material, above suffering."
        quoteFrom="John Thomas Biggers"
        citation="Theisen, Olive Jensen. Walls That Speak: The Murals of John Thomas Biggers. Denton: University of North Texas Press, 19 Aug. 2016. Print."
        link="https://books.google.com/books?id=6OLF_HeRgBsC&pg=PA124&lpg=PA124&dq=John+Thomas+Biggers+interview&source=bl&ots=i6pGTav_R6&sig=51J9obZfxh8WCdP-LRkdKWWxVIE&hl=en&sa=X&ved=2ahUKEwjh2J-_uOHcAhXqg-AKHZnGAL44ChDoATAEegQIBhAB#v=onepage&q=imagination&f=false"
      />
      <Quote
        quote="My life as a poet is completely interwoven with my life as an activist . . . I was called to the struggle, and the struggle liberated me so that I could express my experience in poetry."
        quoteFrom="Minnie Bruce Pratt"
        citation="From an interview published in The Publishing Triangle, conducted by Carol Rosenfeld."
        link="http://www.publishingtriangle.org/prattinterview.asp"
      />
      <Quote
        quote="To be creating anything at all . . . is an act of resistance in a world that silences so many."
        quoteFrom="Saba Taj"
        citation="Kuruvilla, Carol. (2016, June 24). Queer Muslim Artist Saba Taj Sees Her Art As An Act Of Resistance. https://www.huffingtonpost.com"
        link="https://www.huffingtonpost.com/entry/saba-taj-muslim-artist_us_576c63c7e4b0f16832390d80"
      />
      <Quote
        quote="I think the need to help people to be able to speak up, to teach people how to speak up for themselves, is what gave me the drive to keep on pushing . . ."
        quoteFrom="Ann Atwater"
        citation="From an interview published in the Durham VOICE, conducted by Jock Lauterer."
        link="https://www.youtube.com/watch?v=Nl-XNM7Qb1E&t=1s"
      />
      <Quote
        quote="My work tells a story, and that is the most important thing to me, is to tell a story."
        quoteFrom="Karen Lynch Harley"
        citation="North Carolina Folklife Institute."
        link="https://www.ncfolk.org/folk-artists/karen-lynch-harley/"
      />
      <Quote
        quote="Art is the most complete and intense form of expression of the inner life. As such, it has the capacity to change . . . to listen, to bring forth points of view that people can grow from."
        quoteFrom="Ernie Barnes"
        citation={`"TV Land's Here's the Story: Ernie Barnes." Vimeo, 2010.`}
        link="https://vimeo.com/14778844"
      />
      <Quote
        quote="I try to engage people through storytelling . . . I tell them that they can tell their stories, they can express themselves and especially, I tell them, art heals."
        quoteFrom="Rosalía Torres-Weiner"
        citation="MyHome, NC."
        link="http://myhome.unctv.org/rosalia-torres-weiner-artivist/"
      />
      <Quote
        quote="I like being part of this big, sparking, fantastic mind."
        quoteFrom="Manju Rajendran"
        citation="Burge, Bridgette. (2006, February 14). Personal interview with Manju Rajendran."
        link="https://dc.lib.unc.edu/cdm/compoundobject/collection/sohp/id/7977/rec/14"
      />
      <Quote
        quote="As long as you’re dancing together, you have no time for hatred."
        quoteFrom="Baba Chuck Davis"
        citation="National Dance Education Organization."
        link="https://www.ndeo.org/content.aspx?page_id=2507&club_id=893257&item_id=567&pst=4086&sl=974138108"
      />
      <Quote
        quote="This is an ode to the moment before our toes leave the ground, where the impossible doesn't exist and we remember that we don't have to limit ourselves because someone else could not fathom a dream so big."
        quoteFrom="Dare Coulter"
        citation={`Ward, Rebecca and Max Rutledge. "Dare Coulter Right Before We Fly." The Black on Black Project.`}
        link="https://www.blackonblackproject.com/right-before-we-fly/"
      />
    </ol>

    <Typography variant="body1" gutterBottom className={classes.body}>
      We would also like to thank the wonderful people at the Franklin
      Collection at Duke University and their work on the{" "}
      <a
        className={classes.link}
        href={"https://library.duke.edu/digitalcollections/behindtheveil/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Behind the Veil Project
      </a>{" "}
      and the Southern Oral History Program from UNC's{" "}
      <a
        className={classes.link}
        href={"https://sohp.org/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Center for the Study of the American South
      </a>{" "}
      for additional assistance in uncovering stories from the region's past. We
      highly recommend that you check out their resources.
    </Typography>
  </div>
);

export default withStyles(styles)(TheStoryGoesOn);
