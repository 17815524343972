import React from "react";
import {
  Dialog,
  FormGroup,
  FormControlLabel,
  Switch,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography
} from "@material-ui/core";
import { Query, Mutation } from "react-apollo";
import {
  GET_SAVE_DRAWING_MODAL_OPTIONS,
  GET_LAST_SAVED_DRAWING_ID
} from "./queries";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import {
  CREATE_DRAWING,
  TOGGLE_DRAWING_SAVED,
  TOGGLE_SAVE_DRAWING_MODAL
} from "./mutations";
import { toRenderProps, withState, compose } from "recompose";
import { GET_CANVAS } from "../PixelPad";
import { GET_NAV_OPTIONS } from "../Navigation";
import SaveDrawingState from "./state";
import { GET_LOGGED_IN_USER_ID } from "../Login";
import { TRANSPARENT_COLOR_ID } from "../PixelPad/Color";

const WithState = toRenderProps(
  compose(
    withState("title", "updateTitle", ""),
    withState("description", "updateDescription", ""),
    withState("visibleToPublic", "updateVisibleToPublic", false)
  )
);

const Login = ({ classes }) => (
  <WithState>
    {({
      title,
      updateTitle,
      description,
      updateDescription,
      visibleToPublic,
      updateVisibleToPublic
    }) => {
      return (
        <Query query={GET_SAVE_DRAWING_MODAL_OPTIONS}>
          {({ data: { saveDrawingModal }, client }) => (
            <Mutation mutation={TOGGLE_SAVE_DRAWING_MODAL}>
              {toggleSaveDrawingModal => {
                const closeSaveModal = () =>
                  toggleSaveDrawingModal({ variables: { open: false } });
                return (
                  <Mutation mutation={TOGGLE_DRAWING_SAVED}>
                    {toggleDrawingSaved => (
                      <Mutation
                        mutation={CREATE_DRAWING}
                        update={(
                          cache,
                          {
                            data: {
                              createDrawing: { drawingId }
                            }
                          }
                        ) => {
                          console.log(drawingId);
                          cache.writeQuery({
                            query: GET_LAST_SAVED_DRAWING_ID,
                            data: { lastSavedDrawingId: drawingId }
                          });
                        }}
                        onCompleted={createDrawing => {
                          toggleDrawingSaved(true);
                          closeSaveModal();
                        }}
                        onError={evt => {
                          toggleDrawingSaved(false);
                        }}
                      >
                        {(createDrawing, { loading, error }) => (
                          <Dialog
                            open={saveDrawingModal}
                            onClose={closeSaveModal}
                            PaperProps={{ className: classes.paper }}
                          >
                            <DialogContent>
                              {error &&
                                error.graphQLErrors.map(({ message }, i) => (
                                  <Typography
                                    variant="overline"
                                    gutterBottom
                                    key={i}
                                    style={{ color: "#f50057" }}
                                    align="center"
                                  >
                                    {message}
                                  </Typography>
                                ))}
                              <TextField
                                autoFocus
                                margin="dense"
                                id="title"
                                label="Title"
                                type="text"
                                fullWidth
                                value={title}
                                onChange={evt => updateTitle(evt.target.value)}
                              />
                              <TextField
                                margin="dense"
                                id="description"
                                label="Description"
                                type="text"
                                multiline
                                fullWidth
                                value={description}
                                onChange={evt =>
                                  updateDescription(evt.target.value)
                                }
                              />
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={visibleToPublic}
                                      onChange={evt =>
                                        updateVisibleToPublic(
                                          evt.target.checked
                                        )
                                      }
                                      value="visibleToPublic"
                                      color="primary"
                                    />
                                  }
                                  label="Public"
                                />
                              </FormGroup>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                type="submit"
                                onClick={evt => {
                                  //TODO too much logic going on here.. move this elsewhere!
                                  evt.preventDefault();
                                  const {
                                    canvas: { width, height, pixels }
                                  } = client.readQuery({
                                    query: GET_CANVAS
                                  });

                                  //TODO use readFragment instead for reading just the selected site id
                                  const {
                                    navOptions: { selectedSiteId }
                                  } = client.readQuery({
                                    query: GET_NAV_OPTIONS
                                  });
                                  const { userId } = client.readQuery({
                                    query: GET_LOGGED_IN_USER_ID
                                  });
                                  let pixelData = new Array(width * height);
                                  let count = 0;
                                  for (let i = 0; i < pixels.length; i++) {
                                    let columns = pixels[i].columns;
                                    for (let j = 0; j < columns.length; j++) {
                                      let colorId = columns[j].colorId;
                                      if (colorId === TRANSPARENT_COLOR_ID) {
                                        pixelData[count] = null;
                                      } else {
                                        pixelData[count] = {
                                          paletteId: colorId
                                        };
                                      }
                                      count++;
                                    }
                                  }
                                  createDrawing({
                                    variables: {
                                      input: {
                                        title: title,
                                        description: description,
                                        width: width,
                                        height: height,
                                        siteId: selectedSiteId,
                                        userId: userId,
                                        visibleToPublic: visibleToPublic,
                                        pixelData: pixelData
                                      }
                                    }
                                  });
                                }}
                                className={classes.button}
                              >
                                Save
                              </Button>
                            </DialogActions>
                          </Dialog>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                );
              }}
            </Mutation>
          )}
        </Query>
      );
    }}
  </WithState>
);

export default withStyles(styles)(Login);

export { TOGGLE_SAVE_DRAWING_MODAL, SaveDrawingState };
