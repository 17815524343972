import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import classNames from "classnames";
import { GET_PALETTE } from "./queries";
import { UPDATE_SELECTED_COLOR } from "./mutations";
import { Mutation, Query } from "react-apollo";
import { TRANSPARENT_COLOR_ID } from "./constants";

const Color = ({ classes, colorId, hexColor, selected, ...otherProps }) => {
  let boxStyle = {
    border: "#5C5C5C 1px solid"
  };
  if (colorId !== TRANSPARENT_COLOR_ID) {
    boxStyle = {
      background: colorId === TRANSPARENT_COLOR_ID ? "none" : `#${hexColor}`
    };
  }
  return (
    <Mutation mutation={UPDATE_SELECTED_COLOR} variables={{ id: colorId }}>
      {updateSelectedColor => (
        <li className={classes.root} {...otherProps}>
          <Button
            className={classes.button}
            style={boxStyle}
            onClick={e => {
              updateSelectedColor({
                variables: { id: colorId }
              });
            }}
          >
            {""}
          </Button>
          <Query query={GET_PALETTE}>
            {({ data: { selectedColor } }) => (
              <hr
                className={classNames(classes.underline, {
                  [classes.selected]: selectedColor === colorId
                })}
              />
            )}
          </Query>
        </li>
      )}
    </Mutation>
  );
};

export default withStyles(styles)(Color);

export { TRANSPARENT_COLOR_ID };
