export const styles = theme => ({
  subHeader: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    backgroundColor: "#657573",
    color: "#FFF",
    borderBottom: "#FFF solid 1.5px"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  cardGrid: {
    flexGrow: 1,
    backgroundColor: "#4C5F5D"
  }
});
