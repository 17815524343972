import React from "react";
import PropTypes from "prop-types";
import { Query, Mutation } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Button, Grid, Fab } from "@material-ui/core";
import { Brush } from "@material-ui/icons";
import { styles } from "./styles";
import SiteName from "./SiteName";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
//import { Select, MenuItem, InputLabel } from "@material-ui/core";
import {
  TOGGLE_LOGIN_MODAL,
  TOGGLE_REGISTER_MODAL,
  TOGGLE_LOGGED_IN
} from "./mutations";
import { GET_NAV_OPTIONS } from "./queries";
import NavigationState, { NAV_ID } from "./state";
import { PIXELPAD } from "../../constants/routes";
import {
  SHOW_REGISTER_MODAL_FRAGMENT,
  SHOW_LOGIN_MODAL_FRAGMENT
} from "./fragments";
import { CHECK_LOGIN, GET_LOGGED_IN_USER_ID, LOGOUT } from "../Login";
import logo from "./logo.svg";

const Navigation = props => {
  const {
    classes,
    location: { pathname }
  } = props;
  const CreateDrawingLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to="/pixel-pad" {...props} />
  ));
  return (
    <div className={classes.root}>
      <Mutation mutation={TOGGLE_LOGGED_IN}>
        {toggleLoggedIn => (
          <Mutation
            mutation={CHECK_LOGIN}
            notifyOnNetworkStatusChange={true}
            update={(cache, { data: { checkLogin } }) => {
              checkLogin &&
                cache.writeQuery({
                  query: GET_LOGGED_IN_USER_ID,
                  data: { userId: checkLogin }
                });

              toggleLoggedIn({
                variables: { loggedIn: checkLogin ? true : false }
              });
            }}
          >
            {checkLogin => {
              return (
                <Query
                  query={GET_NAV_OPTIONS}
                  onCompleted={evt => {
                    checkLogin();
                  }}
                  notifyOnNetworkStatusChange={true}
                >
                  {({
                    data: {
                      navOptions: { selectedSiteId, loggedIn }
                    }
                  }) => (
                    <AppBar>
                      <Toolbar className={classes.toolBar}>
                        <Grid className={classes.icon}>
                          <img alt="logo" src={logo} />
                        </Grid>
                        <SiteName
                          name="Pixel Patch"
                          subHeader="Creative"
                          linkTo="/"
                        />
                        {!loggedIn && (
                          <React.Fragment>
                            <Mutation mutation={TOGGLE_LOGIN_MODAL}>
                              {toggleLoginModal => (
                                <Button
                                  onClick={evt => {
                                    toggleLoginModal({
                                      variables: { open: true }
                                    });
                                  }}
                                  className={classes.actionButton}
                                >
                                  Login
                                </Button>
                              )}
                            </Mutation>
                            <Mutation mutation={TOGGLE_REGISTER_MODAL}>
                              {toggleRegisterModal => (
                                <Button
                                  onClick={evt => {
                                    toggleRegisterModal({
                                      variables: { open: true }
                                    });
                                  }}
                                  className={classes.actionButton}
                                >
                                  Sign up
                                </Button>
                              )}
                            </Mutation>
                          </React.Fragment>
                        )}
                        {loggedIn && (
                          <Mutation
                            mutation={LOGOUT}
                            update={(cache, { data: { logout } }) => {
                              cache.writeQuery({
                                query: GET_LOGGED_IN_USER_ID,
                                data: { userId: "" }
                              });
                            }}
                            onCompleted={evt => {
                              toggleLoggedIn({
                                variables: { loggedIn: false }
                              });
                            }}
                          >
                            {logout => (
                              <Button
                                onClick={logout}
                                className={classes.actionButton}
                              >
                                Logout
                              </Button>
                            )}
                          </Mutation>
                        )}
                        {PIXELPAD !== pathname && (
                          <Fab
                            variant="extended"
                            color="default"
                            aria-label="Create"
                            className={classes.button}
                            component={CreateDrawingLink}
                          >
                            <Brush className={classes.drawIcon} />
                            Draw!
                          </Fab>
                        )}
                        {/* 
              Future update where we will need the site selection
              <SelectSite selectedSiteId={selectedSiteId} />
               */}
                      </Toolbar>
                    </AppBar>
                  )}
                </Query>
              );
            }}
          </Mutation>
        )}
      </Mutation>
    </div>
  );
};

Navigation.propTypes = {
  classes: PropTypes.object.isRequired
};

/*
// Future update when site selection will be necessary.
// move this to a sub-component
const SelectSite = ({ selectedSiteId }) => (
  <Mutation mutation={UPDATE_SELECTED_SITE} variables={{ selectedSiteId }}>
    {updateSelectedSite => (
      <div>
        <InputLabel htmlFor="site-select">Site</InputLabel>
        <Select
          value={selectedSiteId}
          onChange={e => {
            updateSelectedSite({
              variables: { selectedSiteId: e.target.value }
            });
          }}
          inputProps={{
            name: "site",
            id: "site-select"
          }}
        >
          <MenuItem value="U2l0ZTox">Durham, NC</MenuItem>
          <MenuItem value={"unknown"}>Some Site, MN</MenuItem>
        </Select>
      </div>
    )}
  </Mutation>
);
*/

export default withRouter(withStyles(styles)(Navigation));

export {
  GET_NAV_OPTIONS,
  SHOW_LOGIN_MODAL_FRAGMENT,
  SHOW_REGISTER_MODAL_FRAGMENT,
  TOGGLE_LOGIN_MODAL,
  TOGGLE_REGISTER_MODAL,
  TOGGLE_LOGGED_IN,
  NAV_ID
};

export { NavigationState };
