import gql from "graphql-tag";

export const UPDATE_SELECTED_SITE = gql`
  mutation SelectedSite($selectedSiteId: String!) {
    updateSelectedSite(selectedSiteId: $selectedSiteId) @client
  }
`;

export const TOGGLE_LOGIN_MODAL = gql`
  mutation ToggleLoginModal($open: Boolean!) {
    toggleLoginModal(open: $open) @client
  }
`;

export const TOGGLE_REGISTER_MODAL = gql`
  mutation ToggleRegisterModal($open: Boolean!) {
    toggleRegisterModal(open: $open) @client
  }
`;

export const TOGGLE_LOGGED_IN = gql`
  mutation ToggleLoggedIn($loggedIn: Boolean!) {
    toggleLoggedIn(loggedIn: $loggedIn) @client
  }
`;
