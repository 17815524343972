import gql from "graphql-tag";
import { SITE_BASIC_INFO_FRAGMENT } from "../Site";

export const GET_SITE_PALETTE = gql`
  query GetSitePalette($siteId: ID!) {
    site(id: $siteId) {
      ...siteBasicInfo
      palettes {
        id
        hexColor
      }
    }
  }
  ${SITE_BASIC_INFO_FRAGMENT}
`;
