import { GET_PALETTE } from "./queries";
import { TRANSPARENT_COLOR_ID } from "./constants";

const defaults = {
  selectedColor: TRANSPARENT_COLOR_ID
};

const resolvers = {
  Mutation: {
    updateSelectedColor: (_, { id }, { cache }) => {
      cache.writeQuery({
        query: GET_PALETTE,
        data: {
          selectedColor: id
        }
      });
      return null;
    }
  }
};

export default {
  defaults: defaults,
  resolvers: resolvers
};
