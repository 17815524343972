import React from "react";
import { Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";

const DrawingItem = ({ classes, drawing }) => (
  <div className={classes.container}>
    <img
      className={classes.bg}
      src={`/${drawing.id}_th.svg`}
      alt={drawing.title}
    />
    <Typography className={classes.title} gutterBottom variant="h6">
      {drawing.title}
    </Typography>
    <Button
      target="_blank"
      rel="noopener noreferrer"
      href={`/${drawing.id}_pr.svg`}
      className={classes.export}
      size="small"
      color="primary"
    >
      Export
    </Button>
  </div>
);

export default withStyles(styles)(DrawingItem);
