import React from "react";
import classNames from "classnames";
import { Query } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { GET_SITE } from "./queries";
import { SITE_FRAGMENT, SITE_BASIC_INFO_FRAGMENT } from "./fragments";
import ErrorMessage from "../Error";

const Site = ({ classes, id }) => (
  <Query
    query={GET_SITE}
    notifyOnNetworkStatusChange={true}
    variables={{
      id
    }}
  >
    {({ data, loading, error }) => {
      if (loading) {
        // TODO create a new loading component
        return (
          <div className={classNames(classes.layout, classes.cardGrid)}>
            LOADING..
          </div>
        );
      }

      if (error) {
        return <ErrorMessage error={error} />;
      }
      //const { name, drawings } = data.site;
      return (
        <div className={classes.cardGrid}>
          {/* <Typography variant="h5" align="left" className={classes.subHeader}>
            {name}
          </Typography>

          <div className={classNames(classes.layout, classes.cardGrid)}>
            <DrawingList loading={loading} drawings={drawings} />
          </div> */}
        </div>
      );
    }}
  </Query>
);

export { SITE_FRAGMENT, SITE_BASIC_INFO_FRAGMENT };

export default withStyles(styles)(Site);
