import gql from "graphql-tag";
import { SHOW_REGISTER_MODAL_FRAGMENT } from "../Navigation";

export const GET_REGISTER_MODAL_OPTIONS = gql`
  query GetRegisterModalOptions {
    navOptions @client {
      ...registerModal
    }
  }
  ${SHOW_REGISTER_MODAL_FRAGMENT}
`;
