import gql from "graphql-tag";
import { DRAWING_FRAGMENT } from "../Drawing";

const SITE_BASIC_INFO_FRAGMENT = gql`
  fragment siteBasicInfo on Site {
    id
    name
    canvasWidthMax
    canvasHeightMax
  }
`;

const SITE_FRAGMENT = gql`
  fragment site on Site {
    ...siteBasicInfo
    siteAddress {
      city
      state
    }
    drawings {
      ...drawing
    }
    palettes {
      id
      hexColor
      blockCountMax
    }
  }
  ${SITE_BASIC_INFO_FRAGMENT}
  ${DRAWING_FRAGMENT}
`;

export { SITE_FRAGMENT, SITE_BASIC_INFO_FRAGMENT };
