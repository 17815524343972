import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import classNames from "classnames";
const Quote = ({ classes, quote, quoteFrom, citation, link }) => {
  return (
    <li className={classes.root}>
      <Typography
        variant="subtitle1"
        className={classNames(classes.whiteText, classes.quote)}
      >
        {quote}
      </Typography>
      <Typography
        variant="overline"
        className={classNames(classes.whiteText, classes.quoteFrom)}
      >
        {quoteFrom}
      </Typography>
      <Typography
        variant="subtitle2"
        className={classNames(classes.whiteText, classes.citation)}
      >
        {citation}
      </Typography>
      <a
        className={classNames(classes.whiteText, classes.link)}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography variant="body2" className={classes.whiteText}>
          Link
        </Typography>
      </a>
    </li>
  );
};

export default withStyles(styles)(Quote);
